<template>
  <v-container class="px-6 d-block" fluid>
    <div class="d-flex align-center mb-6">
      <v-icon size="15" color="#4ab762">mdi-home</v-icon>
      <v-breadcrumbs :items="breadcrumbs" class="pa-0 ml-2">
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
    </div>

    <v-row>
      <v-col cols="12">
        <v-card
          class="rounded-lg"
          style="box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 8px"
        >
          <v-card-title
            class="subtitle-1 grey--text text--darken-2 font-weight-bold"
            >Data Laporan Transaksi Kelas Berdasarkan Organisasi</v-card-title
          >
          <v-card-text>
            <v-row>
              <v-col cols="12" sm="5">
                <div class="d-flex align-center">
                <v-text-field
                  dense
                  solo
                  autocomplete="off"
                  color="#4ab762"
                  type="text"
                  :disabled="loading"
                  clearable
                  style="border-radius: 4px 0px 0px 4px"
                  placeholder="Tekan Enter untuk mencari"
                  label="Search"
                  @click:clear="form_filter.title = ''; toFilter() ; options.report.page = 1;;"
                  v-on:keyup.enter="toFilter() ; options.report.page = 1;"
                  v-model="form_filter.title">
                </v-text-field>
                <v-btn
                    color="#4ab762"
                    height="38"
                    :disabled="form_filter.title === '' || form_filter.title === null"
                    style="border-radius: 0px 4px 4px 0px"
                    elevation="0"
                    @click="toFilter() ; options.report.page = 1;"
                    class="text-capitalize white--text">
                    cari
                  </v-btn>
                </div>
              </v-col>
              <v-col cols="12" sm="7" class="d-flex justify-end">
                <v-select
                  dense
                  solo
                  :items="[
                    {
                      name: 'Semua Status',
                      id: ''
                    },
                    {
                      name: 'Selesai',
                      id: 'paid'
                    },
                    {
                      name: 'Menunggu Pembayaran',
                      id: 'waiting'
                    },
                    {
                      name: 'Dibatalkan',
                      id: 'rejected'
                    }
                  ]"
                  item-text="name"
                  item-value="id"
                  hide-details
                  :disabled="loading"
                  item-color="green"
                  style="max-width: 250px"
                  class="mr-2"
                  v-on:change="toFilter() ; options.report.page = 1;"
                  v-model="form_filter.status"
                  label="Status"
                  color="#4ab762">
                  <template v-slot:append>
                    <v-icon color="#7B7B7B">mdi-chevron-down-box</v-icon>
                  </template>
                </v-select>
                <v-autocomplete
                  dense
                  solo
                  :items="organizationData"
                  item-text="name"
                  item-value="id"
                  hide-details
                  :disabled="loading"
                  item-color="green"
                  style="max-width: 250px"
                  class="mr-2"
                  v-on:change="toFilter() ; options.report.page = 1;"
                  v-model="form_filter.organisasi"
                  label="Organisasi"
                  color="#4ab762">
                  <template v-slot:append>
                    <v-icon color="#7B7B7B">mdi-chevron-down-box</v-icon>
                  </template>
                </v-autocomplete>
                <v-btn
                  color="#4ab762"
                  elevation="0"
                  :disabled="loading"
                  @click="inputFilter();"
                  class="text-capitalize white--text mx-3">
                  Filter
                </v-btn>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-text v-if="filter.user !== '' ||  filter.tanggal_mulai !== '' || filter.tanggal_selesai !== '' || filter.transaksi !== ''">
            <!-- <v-divider class="mx-4"/> -->
            <div class="d-flex align-center" style="flex-wrap: wrap;">
              <div v-if="filter.user !== '' ||  filter.tanggal_mulai !== '' || filter.tanggal_selesai !== '' || filter.transaksi !== ''">
                Filter: 
              </div>
              <div v-if="filter.user !== ''">
                <v-chip
                  class="ma-2 white--text"
                  close
                  color="info"
                  @click:close="form_filter.user = ''; options.report.page = 1;  options.report.sortBy = []; options.report.sortDesc = []; toFilter()">
                  Nama Pembeli: {{ filter.user }}
                </v-chip>
              </div>
              <div v-if="filter.tanggal_mulai !== ''">
                <v-chip
                  class="ma-2 white--text"
                  close
                  color="info"
                  @click:close="form_filter.tanggal_mulai = ''; options.report.page = 1;  options.report.sortBy = []; options.report.sortDesc = []; toFilter()">
                  Tanggal lebih dari: {{ filter.tanggal_mulai | dateFullMonth }}
                </v-chip>
              </div>
              <div v-if="filter.tanggal_selesai !== ''">
                <v-chip
                  class="ma-2 white--text"
                  close
                  color="info"
                  @click:close="form_filter.tanggal_selesai = ''; options.report.page = 1;  options.report.sortBy = []; options.report.sortDesc = []; toFilter()">
                  Tanggal kurang dari: {{ filter.tanggal_selesai | dateFullMonth }}
                </v-chip>
              </div>
              <div v-if="filter.transaksi !== ''">
                <v-chip
                  class="ma-2 white--text"
                  close
                  color="info"
                  @click:close="form_filter.transaksi = ''; options.report.page = 1;  options.report.sortBy = []; options.report.sortDesc = []; toFilter()">
                  No Transaksi: {{ filter.transaksi }}
                </v-chip>
              </div>
            </div>
          </v-card-text>
          <v-card-text class="px-0 py-0">
            
            <v-row class="px-5 mb-3">
              <v-col cols="12" md="3">
                <v-skeleton-loader
                  class="ma-auto"
                  :loading="loading"
                  transition="scale-transition"
                  :tile="true"
                  type="image">
                  <v-card
                    class="box-shadow border-radius pa-1"
                    style="border-top: 5px solid #3498DB">
                    <v-card-text>
                      <div style="font-weight: normal; font-size: 14px; color: #505050; line-height: 16px;">
                        Total Transaksi
                      </div>
                      <div style="font-weight: 900; font-size: 24px; color: #505050; line-height: 28px; margin-top: 5px;">
                        {{ totalData.report }}
                      </div>
                      <div style="font-size: 18px; line-height: 14px; color: #3498DB; mix-blend-mode: normal; opacity: 1;" class="mt-3 font-weight-bold">
                        <div style="font-size: 12px; color: #505050; margin-bottom: 8px; font-weight: normal;">
                          Nilai
                        </div>
                        {{ summary.sum_total_paid_nett === null ? 0 : summary.sum_total_paid_nett | price }}
                      </div>
                    </v-card-text>
                  </v-card>
                </v-skeleton-loader>
              </v-col>
              <v-col cols="12" md="3">
                <v-skeleton-loader
                  class="ma-auto"
                  :loading="loading"
                  transition="scale-transition"
                  :tile="true"
                  type="image">
                  <v-card
                    class="box-shadow border-radius pa-1"
                    style="border-top: 5px solid #fb8c00">
                    <v-card-text>
                      <div style="font-weight: normal; font-size: 14px; color: #505050; line-height: 16px;">
                        Menunggu Komfirmasi
                      </div>
                      <div style="font-weight: 900; font-size: 24px; color: #505050; line-height: 28px; margin-top: 5px;">
                        {{ summary.count_status_waiting }}
                      </div>
                      <div style="font-size: 18px; line-height: 14px; color: #fb8c00; mix-blend-mode: normal; opacity: 1;" class="mt-3 font-weight-bold">
                        <div style="font-size: 12px; color: #505050; margin-bottom: 8px; font-weight: normal;">
                          Nilai
                        </div>
                        {{ summary.sum_total_paid_nett_waiting === null ? 0 : summary.sum_total_paid_nett_waiting | price }}
                      </div>
                    </v-card-text>
                  </v-card>
                </v-skeleton-loader>
              </v-col>
              <v-col cols="12" md="3">
                <v-skeleton-loader
                  class="ma-auto"
                  :loading="loading"
                  transition="scale-transition"
                  :tile="true"
                  type="image">
                  <v-card
                    class="box-shadow border-radius pa-1"
                    style="border-top: 5px solid rgb(46 204 113)">
                    <v-card-text>
                      <div style="font-weight: normal; font-size: 14px; color: #505050; line-height: 16px;">
                        Selesai
                      </div>
                      <div style="font-weight: 900; font-size: 24px; color: #505050; line-height: 28px; margin-top: 5px;">
                        {{ summary.count_status_paid }}
                      </div>
                      <div style="font-size: 18px; line-height: 14px; color: rgb(46 204 113); mix-blend-mode: normal; opacity: 1;" class="mt-3 font-weight-bold">
                        <div style="font-size: 12px; color: #505050; margin-bottom: 8px; font-weight: normal;">
                          Nilai
                        </div>
                        {{ summary.sum_total_paid_nett_paid === null ? 0 : summary.sum_total_paid_nett_paid | price }}
                      </div>
                    </v-card-text>
                  </v-card>
                </v-skeleton-loader>
              </v-col>
              <v-col cols="12" md="3">
                <v-skeleton-loader
                  class="ma-auto"
                  :loading="loading"
                  transition="scale-transition"
                  :tile="true"
                  type="image">
                  <v-card
                    class="box-shadow border-radius pa-1"
                    style="border-top: 5px solid rgb(231, 76, 60)">
                    <v-card-text>
                      <div style="font-weight: normal; font-size: 14px; color: #505050; line-height: 16px;">
                        Dibatalkan
                      </div>
                      <div style="font-weight: 900; font-size: 24px; color: #505050; line-height: 28px; margin-top: 5px;">
                        {{ parseInt(summary.count_status_cancelled) + parseInt(summary.count_status_rejected) }}
                      </div>
                      <div style="font-size: 18px; line-height: 14px; color: rgb(231, 76, 60); mix-blend-mode: normal; opacity: 1;" class="mt-3 font-weight-bold">
                        <div style="font-size: 12px; color: #505050; margin-bottom: 8px; font-weight: normal;">
                          Nilai
                        </div>
                        {{ (parseInt(summary.sum_total_paid_nett_rejected) + parseInt(summary.sum_total_paid_nett_cancelled)) | price }}
                      </div>
                    </v-card-text>
                  </v-card>
                </v-skeleton-loader>
              </v-col>
              <!-- <v-col cols="12" md="4">
                <v-skeleton-loader
                  class="ma-auto"
                  :loading="loading"
                  transition="scale-transition"
                  :tile="true"
                  type="image">
                  <v-card
                    class="box-shadow border-radius pa-1">
                    <v-card-text class="subtitle-2 text-second">
                      Total Transaksi
                    </v-card-text>
                    <v-card-text class="text-h4 pt-0">
                      {{ totalData.report }} 
                    </v-card-text>
                  </v-card>
                </v-skeleton-loader>
              </v-col>
              <v-col cols="12" md="8">
                <v-skeleton-loader
                  class="ma-auto"
                  :loading="loading"
                  transition="scale-transition"
                  :tile="true"
                  type="image">
                  <v-card
                    class="box-shadow border-radius pa-1">
                    <v-card-text class="subtitle-2 text-second">
                      Total Nominal Transaksi
                    </v-card-text>
                    <v-card-text class="text-h4 pt-0">
                      {{ summary.sum_total_paid_nett | price }}
                    </v-card-text>
                  </v-card>
                </v-skeleton-loader>
              </v-col> -->
            </v-row>
            <div class="px-4 d-flex justify-space-between">
              <v-btn-toggle dense class="mb-2">

                <v-btn
                  color="#3498db"
                  class="text-none"
                  :disabled="loading || selected.report.length <= 0"
                  text
                  small
                  @click="dialog.detail = true"
                >
                  <v-icon size="16" color="#3498db"> mdi-information </v-icon>
                  <span class="hidden-sm-and-down ml-1">Detail</span>
                </v-btn>
              </v-btn-toggle>
              <v-btn-toggle dense class="mb-2">
                <v-btn
                  color="#27ae60"
                  class="text-none"
                  text
                  small
                  :disabled="loading"
                  @click="exportData"
                >
                  <v-icon size="16" color="#27ae60"> mdi-file-excel </v-icon>
                  <span class="hidden-sm-and-down ml-1">Export Excel</span>
                </v-btn>
              </v-btn-toggle>
            </div>
            <v-data-table
              v-model="selected.report"
              :headers="headers.report"
              :items="dataGrid.report"
              :loading="loading"
              :options.sync="options.report"
              :server-items-length="totalData.report"
              loading-text="Sedang memuat"
              :no-data-text="'Data belum ada'"
              no-results-text="Data belum ada"
              sort-by-text="Urutkan berdasarkan"
              :items-per-page="10"
              height="calc(100vh - 540px)"
              fixed-header
              :footer-props="footerProps"
              item-key="id"
              flat
              single-select
              show-select
              class="elevation-0 custom-grid rounded-lg td-top"
            >

              <template v-slot:[`item.created_datetime`]="{ item }">
                {{ item.created_datetime | datetime | emptyData }}
              </template>

              <template v-slot:[`item.total_paid`]="{ item }">
                {{ item.total_paid | price | emptyData }}
              </template>

              <template v-slot:[`item.admin_fee`]="{ item }">
                {{ item.admin_fee | price | emptyData }}
              </template>

              <template v-slot:[`item.total_discount_value`]="{ item }">
                {{ item.total_discount_value | price | emptyData }}
              </template>

              <template v-slot:[`item.total_paid_nett`]="{ item }">
                <div class="font-weight-bold" :style="item.status === 'paid' ? 'color: rgb(46 204 113)' : item.status === 'waiting' ? 'color: #fb8c00' : 'color: rgb(231, 76, 60)'">
                  {{ item.total_paid_nett | price | emptyData }}
                </div>
              </template>

              <template v-slot:[`item.status`]="{ item }">
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(46 204 113);
                    background-color: rgb(46 204 113 / 10%);
                  "
                  v-if="item.status === 'paid'"
                >
                  Selesai
                </v-chip>
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: #fb8c00;
                    background-color: #fb8c0024;
                  "
                  v-if="item.status === 'waiting'"
                >
                  Menunggu Pembayaran
                </v-chip>
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(231, 76, 60);
                    background-color: rgb(231 76 60 / 10%);
                  "
                  v-if="item.status === 'rejected' || item.status === 'cancelled'"
                >
                  Dibatalkan
                </v-chip>
              </template>

              <template v-slot:[`item.detail`]="{ item }">
                <div v-for="(l, i) in item.detail" :key="i">
                  <v-list-item class="py-0 px-0 mt-n3">
                    <v-list-item-content class="py-0" style="max-width: 30px">
                      <v-list-item-title
                        class="subtitle-2 grey--text text--darken-1 text-left"
                        >{{ i+1 }}.</v-list-item-title
                      >
                    </v-list-item-content>
                    <!-- <v-list-item-avatar
                      tile
                      size="32"
                      class="rounded mr-2"
                      color="#f7f7f7"
                    >
                      <span v-if="l.course_img_url == ''">{{
                        l.course_name | initial
                      }}</span>
                      <v-img v-else :src="l.course_img_url"></v-img>
                    </v-list-item-avatar> -->
                    <v-list-item-content class="py-0" style="max-width: 220px">
                      <v-list-item-title
                        class="subtitle-2 grey--text text--darken-1 text-left"
                        >{{ l.course_name }}</v-list-item-title
                      >
                    </v-list-item-content>
                  </v-list-item>
                </div>
              </template>

              <template v-slot:[`footer.page-text`]="props">
                Menampilkan {{ props.pageStart }} - {{ props.pageStop }} data
                dari total {{ props.itemsLength }} data
                <v-btn
                  text
                  class="ml-4 text-none"
                  small
                  @click="
                    fetchData();
                    selected.report = [];
                  "
                  ><v-icon left>mdi-sync</v-icon> Perbarui</v-btn
                >
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <!-- FORM -->
    <v-dialog
      v-model="dialog.filter"
      v-if="dialog.filter"
      persistent
      max-width="450"
      scrollable>
      <v-card tile>
        <v-card-title
          class="subtitle-1 px-4 py-2 d-block"
          style="color: #4ab762"
        >
          Filter Data Laporan <br />
          <div class="caption grey--text text--darken-1">
            Isi form dibawah ini untuk memfilter
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text class="py-4 px-4">
          <v-form
            ref="form-report"
            v-model="valid"
            lazy-validation
            @keyup.native.enter="toFilter"
          >
            <div class="subtitle-2">
              Nama Pembeli
            </div>
            <v-text-field
              dense
              solo
              autocomplete="off"
              color="#4ab762"
              type="text"
              placeholder="Tulis disini"
              class="rounded-lg mb-3"
              :disabled="loading"
              v-model="form_filter.user">
            </v-text-field>
            <div class="subtitle-2 mb-4">
              Tanggal Transaksi
            </div>
            <v-row>
              <v-col :cols="6" class="py-0">
                <v-menu
                  v-model="filter_start_date"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form_filter.tanggal_mulai"
                      :label="'Mulai Dari'"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      v-on:change="form_filter.tanggal_selesai = '';"
                      dense
                      solo
                      autocomplete="off"
                      color="#4ab762"
                      type="text"
                      placeholder="Tulis disini"
                      class="rounded-lg mb-3"
                      :disabled="loading"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form_filter.tanggal_mulai"
                    color="#4ab762"
                    :max="reminder"
                    @input="filter_start_date = false; changeDate()"
                  ></v-date-picker>
                </v-menu>
              </v-col>
              <v-col cols="6" class="py-0">
                <v-menu
                  v-model="filter_end_date"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  transition="scale-transition"
                  offset-y
                  min-width="auto"
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-text-field
                      v-model="form_filter.tanggal_selesai"
                      :label="'Kurang Dari'"
                      prepend-inner-icon="mdi-calendar"
                      readonly
                      :disabled="loading || form_filter.tanggal_mulai === ''"
                      dense
                      solo
                      autocomplete="off"
                      color="#4ab762"
                      type="text"
                      placeholder="Tulis disini"
                      class="rounded-lg mb-3"
                      v-bind="attrs"
                      v-on="on"
                    ></v-text-field>
                  </template>
                  <v-date-picker
                    v-model="form_filter.tanggal_selesai"
                    color="#4ab762"
                    :min="form_filter.tanggal_mulai"
                    :max="max_tanggal"
                    @input="filter_end_date = false"
                  ></v-date-picker>
                </v-menu>
              </v-col>
            </v-row>
            <div class="subtitle-2 mt-4">
              No Transaksi
            </div>
            <v-text-field
              dense
              solo
              autocomplete="off"
              color="#4ab762"
              type="text"
              placeholder="Tulis disini"
              class="rounded-lg mb-3"
              :disabled="loading"
              v-model="form_filter.transaksi">
            </v-text-field>
          </v-form>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-space-between px-4">
          <v-btn
            rounded
            depressed
            class="text-none white--text text--lighten-3 flex-grow-1"
            color="#4ab762"
            @click="toFilter()"
          >
            <v-icon left>mdi-check-circle-outline</v-icon
            >Simpan Filter
          </v-btn>
          <v-btn
            rounded
            depressed
            outlined
            class="text-none"
            color="#e74c3c"
            width="130"
            @click="inputFilter(); dialog.filter = false"
            >Batal</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="dialog.detail"
      v-if="dialog.detail"
      max-width="450"
      scrollable
    >
      <v-card tile>
        <v-card-title
          class="subtitle-1 px-4 py-2 d-block"
          style="color: #4ab762"
        >
          Detail Data Laporan
          <div class="caption grey--text text--darken-1">
            Informasi Data Laporan
          </div>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text
          style="height: calc(100vh - 118px)"
          class="py-4 px-0"
          v-if="selected.report.length > 0"
        >
          <div class="px-4">
            <!-- <div class="d-flex justify-center align-center">
              <v-avatar tile class="rounded-lg" size="80" color="#ecf0f1">
                <span
                  v-if="selected.report[0].img_url == ''"
                  style="font-size: 42px"
                  >{{ selected.report[0].name | initial }}</span
                >
                <v-img v-else :src="selected.report[0].img_url"></v-img>
              </v-avatar>
            </div> -->
            <div class="my-3 d-flex align-center">
              <div class="caption">Nomer Transaksi</div>
              <div class="ml-auto subtitle-2 font-weight-bold">
                {{ selected.report[0].number | emptyData }}
              </div>
            </div>
            <div class="my-3 d-flex align-center">
              <div class="caption">Tanggal Pembelian</div>
              <div class="ml-auto subtitle-2 font-weight-bold">
                {{ selected.report[0].created_datetime | dateFull | emptyData }}
              </div>
            </div>
            <div class="my-3 d-flex align-center">
              <div class="caption">Nama Pembeli</div>
              <div class="ml-auto subtitle-2 font-weight-bold">
                {{ selected.report[0].user_name | emptyData }}
              </div>
            </div>
            <div class="my-3 d-flex align-center">
              <div class="caption">Status</div>
              <div class="ml-auto subtitle-2 font-weight-bold">
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(46 204 113);
                    background-color: rgb(46 204 113 / 10%);
                  "
                  v-if="selected.report[0].status === 'paid'"
                >
                  Selesai
                </v-chip>
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: #fb8c00;
                    background-color: #fb8c0024;
                  "
                  v-if="selected.report[0].status === 'waiting'"
                >
                  Menunggu Pembayaran
                </v-chip>
                <v-chip
                  small
                  class="ma-2 font-weight-bold"
                  label
                  style="
                    color: rgb(231, 76, 60);
                    background-color: rgb(231 76 60 / 10%);
                  "
                  v-if="selected.report[0].status === 'rejected' || selected.report[0].status === 'cancelled'"
                >
                  Dibatalkan
                </v-chip>
              </div>
            </div>
            <div class="my-3 d-flex align-center" v-if="selected.report[0].status === 'rejected' || selected.report[0].status === 'cancelled'">
              <div class="caption">Alasan</div>
              <div class="ml-auto subtitle-2 font-weight-bold">
                {{ selected.report[0].rejected_reason | emptyData }}
              </div>
            </div>
            <div class="my-3 d-flex align-center" v-if="selected.report[0].status === 'rejected' || selected.report[0].status === 'cancelled'">
              <div class="caption">Tanggal Pembatalan</div>
              <div class="ml-auto subtitle-2 font-weight-bold">
                {{ selected.report[0].rejected_datetime | dateFull | emptyData }}
              </div>
            </div>
            <div class="my-3 d-flex align-center">
              <div class="caption">Total Harga Kelas</div>
              <div class="ml-auto subtitle-2 font-weight-bold primary--text">
                {{ selected.report[0].total_paid | price }}
              </div>
            </div>
            <div class="my-3 d-flex align-center">
              <div class="caption">Voucher</div>
              <div class="ml-auto subtitle-2 font-weight-bold">
                {{ selected.report[0].total_discount_value | price | emptyData }}
              </div>
            </div>
            <div class="my-3 d-flex align-center">
              <div class="caption">Biaya Admin</div>
              <div class="ml-auto subtitle-2 font-weight-bold red--text">
                {{ selected.report[0].admin_fee | price | emptyData }}
              </div>
            </div>
            <div class="my-3 d-flex align-center">
              <div class="caption">Total Pembelian</div>
              <div class="ml-auto subtitle-2 font-weight-bold green--text">
                {{ selected.report[0].total_paid_nett | price | emptyData }}
              </div>
            </div>
          </div>

          <div
            style="background: rgba(0, 0, 0, 0.1)"
            class="mt-6 px-4 py-2 subtitle-2"
            v-if="parseInt(selected.report[0].total_paid_nett) !== 0"
          >
            PEMBAYARAN
          </div>

          <div class="px-4" v-if="parseInt(selected.report[0].total_paid_nett) !== 0">
            <v-list-item class="px-0 mt-3">
              <v-list-item-avatar
                color="#FFF"
                width="80"
                class="mt-0"
                style="border-radius: 6px !important;">
                <v-img contain :src="selected.report[0].payment_image"></v-img>
              </v-list-item-avatar>
              <v-list-item-content class="pt-0">
                <div class="subtitle-2 font-weight-bold font-weight-bold">
                  {{ selected.report[0].payment_name }}
                </div>
                <!-- <v-list-item-subtitle class="caption mt-2" v-if="selected.report[0].payment_va_number !== ''">
                  Nomor Rekening
                </v-list-item-subtitle> -->
              </v-list-item-content>
            </v-list-item>
          </div>

          <div
            style="background: rgba(0, 0, 0, 0.1)"
            class="mt-2 px-4 py-2 subtitle-2"
          >
            DATA KELAS
          </div>

          <div class="px-4">
            <v-list dense>
              <v-list-item
                class="py-0 px-0"
                v-for="(item, index) in selected.report[0].detail"
                :key="index"
              >
                <v-list-item-avatar
                  class="py-0 rounded-lg"
                  tile
                  color="#ecf0f1"
                >
                  <span v-if="!item.course_img_url" style="font-size: 14px">{{
                    item.course_name | initial
                  }}</span>
                  <v-img v-else :src="item.course_img_url"></v-img>
                </v-list-item-avatar>
                <v-list-item-content class="py-0">
                  <v-list-item-title
                    class="subtitle-2 grey--text text--darken-1"
                    >{{ item.course_name | emptyData }}</v-list-item-title
                  >
                </v-list-item-content>
              </v-list-item>
            </v-list>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="justify-space-between px-4">
          <v-btn
            color="#e74c3c"
            outlined
            depressed
            rounded
            @click="dialog.detail = false"
            class="text-none flex-grow-1"
          >
            Tutup
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
export default {
  name: "Laporan Transaksi Kelas Berdasarkan Organisasi",

  data: () => ({
    breadcrumbs: [
      {
        text: "Dashboard",
        disabled: false,
        href: "/",
      },
      {
        text: "Laporan Transaksi Kelas Berdasarkan Organisasi",
        disabled: true,
        href: "/laporan-transaksi-organisasi",
      },
    ],

    loading: false,
    dialog: {
      report: false,
      confirmStatus: false,
      detail: false,
      filter: false
    },
    selected: {
      report: [],
    },
    max_tanggal: '',
    menu: [
      'Menunggu Konfirmasi', 'Menunggu Approval', 'Selesai',  'Ditolak'
    ],
    tab: 0,
    filter_start_date: false,
    filter_end_date: false,
    filter: {
      title: '',
      status: '',
      user: '',
      tanggal_mulai: "",
      tanggal_selesai: "",
      transaksi: "",
      organisasi: ""
    },
    form_filter: {
      title: '',
      status: '',
      user: '',
      tanggal_mulai: "",
      tanggal_selesai: "",
      transaksi: "",
      organisasi: ""
    },
    headers: {
      report: [
        {
          text: "NOMOR TRANSAKSI",
          width: "180",
          align: "start",
          sortable: true,
          value: "number",
        },

        {
          text: "TANGGAL TRANSAKSI",
          width: "190",
          align: "start",
          sortable: true,
          value: "created_datetime",
        },
        {
          text: "NAMA PEMBELI",
          width: "250",
          align: "start",
          sortable: true,
          value: "user_name",
        },
        {
          text: "STATUS PEMBELIAN",
          width: "180",
          align: "center",
          sortable: false,
          value: "status",
        },

        {
          text: "NAMA KELAS",
          width: "250",
          align: "start",
          sortable: true,
          value: "detail",
        },

        {
          text: "HARGA KELAS",
          width: "180",
          align: "end",
          sortable: true,
          value: "total_paid",
        },
        {
          text: "VOUCHER",
          width: "180",
          align: "end",
          sortable: true,
          value: "total_discount_value",
        },
        {
          text: "BIAYA ADMIN",
          width: "150",
          value: "admin_fee",
          align: "end",
        },

        {
          text: "TOTAL PEMBELIAN",
          width: "200",
          value: "total_paid_nett",
          align: "end",
        },
        // {
        //   text: "JENIS KELAMIN",
        //   width: "180",
        //   value: "gender",
        //   align: "center",
        // },
        // {
        //   text: "TERAKHIR LOGIN",
        //   width: "180",
        //   value: "last_login_datetime",
        //   align: "center",
        // },
      ],
    },
    dataGrid: {
      report: [],
    },
    options: {
      report: {},
    },
    totalData: {
      report: 0,
    },
    footerProps: {
      "show-current-page": true,
      "show-first-last-page": true,
      "items-per-page-options": [10, 15, 30, 50, 100],
      "items-per-page-text": "Data per halaman",
      "page-text": "{0} - {1} dari total {2}",
    },

    summary: {},

    valid: true,
    form: {
      id: "",
      name: "",
      email: "",
      contact: "",
      organization_id_array: ["1"],
    },
    formStatus: {
      id: "",
      name: "",
      active_bool: false,
    },
    validation: {
      id: "",
      name: "",
      email: "",
      contact: "",
      organization_id_array: "",
    },
    organizationData: [],
  }),

  watch: {
    "options.report": {
      async handler() {
        await this.fetchData();
      },
      deep: true,
    },
  },

  computed: {
    reminder () {
      let now = new Date(),
          next = new Date(now)
      next.setDate(now.getDate())
      return next.toISOString().substr(0, 10)
    },
  },

  mounted() {
    this.fetchOrganization();
  },

  methods: {
    async fetchOrganization() {
      this.$store.state.overlay.show = true;
      this.$store.state.overlay.text = "Memuat data organisasi...";

      const response = await this.$get(`organization`, {
        limit: 10000000
      });

      if (response.status === 200) {
        this.organizationData = [
          {
            id: "",
            name: "Semua Organisasi"
          }
        ]
        for (let index = 0; index < response.results.data.length; index++) {
          this.organizationData.push(response.results.data[index])
        }
        for (let index = 0; index < this.organizationData.length; index++) {
          if (this.organizationData[index].id === '1') {
            this.organizationData[index].disabled = false
          }
        }
      } else {
        this.$store.state.snackbar = {
          show: true,
          text: "Gagal memuat data organisasi.",
          color: "#f39c12",
        };
      }

      this.$store.state.overlay.show = false;
    },
    changeDate() {
      let now = new Date(this.form_filter.tanggal_mulai),
          next = new Date(now)
      next.setDate(now.getDate()+90)
      this.max_tanggal = next.toISOString().substr(0, 10)
    },


    toFilter(item) {
      this.filter = {
        title: this.form_filter.title === null ? '' : this.form_filter.title,
        status: this.form_filter.status,
        user: this.form_filter.user,
        tanggal_mulai: this.form_filter.tanggal_mulai,
        tanggal_selesai: this.form_filter.tanggal_selesai,
        transaksi: this.form_filter.transaksi,
        organisasi: this.form_filter.organisasi
      },
      this.filter.status = this.form_filter.status === "Selesai" ? "Diterima" : this.form_filter.status === "Ditolak" ? "Ditolak" : this.filter.status === "Menunggu Konfirmasi" ? "Menunggu Pembayaran" : this.filter.status
      this.fetchData()
      this.dialog.filter = false
    },

    inputFilter(item) {
      this.form_filter = {
        title: this.filter.title,
        status: this.filter.status === "Diterima" ? "Selesai" : this.filter.status === "Ditolak" ? "Ditolak" : this.filter.status === "Menunggu Pembayaran" ? "Menunggu Konfirmasi" : this.filter.status,
        user: this.filter.user,
        tanggal_mulai: this.filter.tanggal_mulai,
        tanggal_selesai: this.filter.tanggal_selesai,
        transaksi: this.filter.transaksi,
        organisasi: this.filter.organisasi
      },
      this.dialog.filter = true
    },

    async fetchData() {
      this.loading = true;
      this.filter.organisasi === null ? this.filter.organisasi = '' : this.filter.organisasi
      this.form_filter.organisasi === null ? this.form_filter.organisasi = '' : this.form_filter.organisasi

      let sign =
        this.options.report.sortDesc && this.options.report.sortDesc[0]
          ? "-"
          : "";

      let params = {
        page: this.options.report.page,
        limit: this.options.report.itemsPerPage,
        sort: "-id",
        'user_name[lse]': this.filter.user,
        'number[lse]': this.filter.transaksi,
        'created_datetime[gte]': this.filter.tanggal_mulai,
        'created_datetime[lte]': this.filter.tanggal_selesai,
        course_organization_id: this.filter.organisasi,
        status: this.filter.status
      };

      if (
        this.options.report.sortBy.length > 0 &&
        this.options.report.sortDesc.length > 0
      ) {
        params.sort =
          sign +
          (this.options.report.sortBy && this.options.report.sortBy[0]);
      }

      let response = await this.$get("admin/report/transaction", params);

      this.loading = false;

      if (response.status == 200) {
        this.dataGrid.report = response.results.data;
        this.summary = response.results.summary
        this.totalData.report = parseInt(
          response.results.pagination.total_data
        );
      } else {
        this.$store.state.snackbar = {
          show: true,
          text: "Gagal memuat data.",
          color: "#f39c12",
        };
      }
    },

    async exportData() {
      this.$store.state.overlay.show = true;
      this.$store.state.overlay.text = "Mengexport data...";

      let sign =
        this.options.report.sortDesc && this.options.report.sortDesc[0]
          ? "-"
          : "";

      let params = {
        page: this.options.report.page,
        limit: this.options.report.itemsPerPage,
        sort: "-id",
        'user_name[lse]': this.filter.user,
        'number[lse]': this.filter.transaksi,
        'created_datetime[gte]': this.filter.tanggal_mulai,
        'created_datetime[lte]': this.filter.tanggal_selesai,
        course_organization_id: this.filter.organisasi,
        status: this.filter.status
      };

      if (
        this.options.report.sortBy.length > 0 &&
        this.options.report.sortDesc.length > 0
      ) {
        params.sort =
          sign +
          (this.options.report.sortBy && this.options.report.sortBy[0]);
      }

      let response = await this.$get("admin/report/transaction/export_xls", params);

      this.$store.state.overlay.show = false;
      this.$store.state.overlay.text = "";

      if (response.status == 200) {
        this.$store.state.snackbar = {
          show: true,
          text: "Berhasil mengexport data.",
          color: "#2ecc71",
        };

        window.open(response.results.data.export_url, "_blank");
      } else {
        this.$store.state.snackbar = {
          show: true,
          text: "Gagal mengexport data.",
          color: "#f39c12",
        };
      }
    },
  },
};
</script>

<style>
  .td-top tr td {
    vertical-align: top !important;
    padding-top: 10px !important;
  }
</style>